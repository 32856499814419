.BackgroundContainer {
  position: absolute;
  left: 0;
  top: 0;
  z-index: -99;

  width: 100vw;
  height: 100vh;
}

@keyframes fadeInOut {
  0% {
    opacity:1;
  }
  25% {
    opacity:0;
  }
  75% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

.BackgroundContainer img { 
  width: 100vw;
  height: 100vh;
  position: absolute;
  overflow: hidden;

  -o-object-fit: cover;
  object-fit: cover;

  animation-name: fadeInOut;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 6s;
}

.BackgroundContainer img:nth-of-type(1) {
  animation-delay: 5s;
}
.BackgroundContainer img:nth-of-type(2) {
  animation-delay: 3s;
}
.BackgroundContainer img:nth-of-type(3) {
  animation-delay: 1s;
}
