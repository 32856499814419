.SlideFadeFromRight-enter {
  opacity: 0.01;
  transform: translate(5%);
}

.SlideFadeFromRight-enter.SlideFadeFromRight-enter-active {
  opacity: 1;
  transform: translate(0);
  transition: all 500ms ease-out;
}

.SlideFadeFromRight-leave {
  transform: translate(0);
  opacity: 1;
}

.SlideFadeFromRight-leave.SlideFadeFromRight-leave-active {
  opacity: 0.01;
  transform: translate(5%);
  transition: all 500ms ease-out;
}