.FooterContainer {
  background-color: #505050;
  width: 100vw;
  padding-top: 0.5rem;
  padding-bottom: 0.25rem;

  line-height: 1.5;
  font-size: 1.0rem;
  color: #6c757d;

  text-align: center;
}

.FooterContainer a {
  line-height: 1;
  text-decoration: underline;
  text-decoration-color: #ffff00;
  color: #f8f9fa;
  font-size: 1.25rem;
}
