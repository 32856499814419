.ShowcaseImgFluid {
  max-width: 100%;
  max-height: 80vh;
  margin: 10px auto;
}

.ShowcaseContentSection {
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}