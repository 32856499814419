.NavbarContainer {
  background-color: #505050;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1023;

  min-height: 3rem;

  padding: .5rem 1rem;

  box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175);

  display: flex;

  align-items: center;
}

.NavbarContainer div {
  margin-left: auto;
}

.Logo {
  height: 30px;
  margin-top: 4px;
}

.HamburgerMenuContainer a {
  margin-top: 0.2rem;
  text-decoration: underline;
  text-decoration-color: #ffff00;
}

.PostNavbarSpacing {
  height: 4rem;
}
