.Marginless {
  margin: 0 auto;
}

.Backgrounded {
  background-image: url(./img/background3.jpg);
  background-size: cover;
}

.CoverContainer {
	display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  min-height: 100vh;
  overflow: hidden;

	max-width: 100%;
}

.CoverHeader {
	align-self: flex-start;
  margin: 0 auto;
  margin-bottom: auto;
}

.CoverContent {
  padding: 0 1.5rem;

	display: flex;
	flex-direction: column;
}

.CoverFooter {
  margin-top: auto;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.Content {
  background-color: #3a3a3a;
}

.ContentSection {
  padding: 0.5rem 1.2rem;
}

.Navbar {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	list-style: none;
}

.NavElement {
	padding: .25rem 0;
}

.Navbar .NavElement + .NavElement {
	margin-left: 1.5rem;
}

.NavElementHuge {
  padding: 1rem 0;
}

.Navbar .NavElementHuge + .NavElementHuge {
  margin-left: 5rem;
}

.ImgHuge {
  max-width: 25vw;
}

.ImgImportant {
  border-bottom: 4px solid #ffff00;
}

.CenterBlock {
  display: block;
  margin: 0 auto;
}

.ImgFluid {
  max-width: 100%;
}

.ImgFluidMinimized {
  max-width: 60%;
}

.ImgFluidMicro {
  max-width: 40%;
}

.ImgTiny {
  max-width: 200px;
}

.EmbedFluid {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
  margin-bottom: 16px;
}

.EmbedFluid::before {
  padding-top: 100%;
  display: block;
  content: "";
}

.EmbedFluidShort {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
  margin-bottom: 16px;
}

.EmbedFluidShort::before {
  padding-top: 30%;
  display: block;
  content: "";
}

.EmbedFluidItem {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.TextFocus {
  text-align: center;
  font-size: 1.25rem;
  font-weight: 300;

  margin-bottom: 16px;
  
  text-shadow: 0 0.05rem .1rem rgba(0, 0, 0, .5);
}

.TextLeft {
  text-align: left !important;
}

.TextNormal {
  text-align: justify;
  font-size: 1.1rem;
  font-weight: 300;
}

.TextGrand {
  font-weight: 700;
  font-size: 1.25rem;
  text-shadow: 0 0.05rem .1rem rgba(0, 0, 0, .5);
  margin-top: 0.1rem;
  margin-bottom: 0.2rem;
}

.TextHuge {
  font-weight: 700;
  font-size: 1.8rem;
  margin-top: 0.2rem;
  margin-bottom: 0.4rem;
}

.TextTitle {
  margin-top: 16px;
  text-align: center;
}

.TextCenter {
  text-align: center;
}

.TextRight {
  text-align: right;
}

.TextImportant {
  text-decoration: underline;
  text-decoration-color: #ffff00;
}

.Btn {
	align-self: center;

  padding-top: .2rem;
  padding-bottom: .2rem;
  padding-left: .5rem;
  padding-right: .5rem;

  color: #ffff00;
  text-align: center;
  text-shadow: 0 0.05rem .1rem rgba(0, 0, 0, .5);

  border: 1px solid transparent;
  border-width: 3px;
  border-color: #ffff00;
  border-radius: 0;
}

.SocialIcon {
  font-size: 1.8rem;
  padding: 5px;
}

.SocialIconHuge {
  font-size: 2.5rem;
  padding: 0.5rem;
}

a {
  text-decoration: underline;
  text-decoration-color: #ffff00;
  color: #f8f9fa;
  text-align: center;
}

.ContentPadded {
  padding: 2rem 6rem;
}

.FlexLRSplit {
  display: flex;
  align-items: center;
}

.FlexLRSplit * {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.CenteredChildren {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.FlexColumn {
  flex-direction: column;
}

.BlockCenter {
  margin: 0 auto !important;
}