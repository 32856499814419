.SplitView {
  width: 100%;
  height: 100vh;

  display: flex;
}

.SplitViewMaster {
  width: 30%;
  height: 100%;
  background-color: #303030;

  background-image: url(../img/background3.jpg);
  background-size: cover;
}

.SplitViewMasterBody {
  margin-top: 1rem;
  align-self: flex-end;
  margin-right: 4rem;
}

.SplitViewDetails {
  height: 100vh;
  overflow: auto;
  overflow-x: hidden;
  width: 70%;
  box-shadow: 0px 0 50px 0 rgba(0, 0, 0, 0.5);
}