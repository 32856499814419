.HamburgerButtonBar {
  width: 25px;
  height: 3px;
  margin: 4px 0;
  background-color: #f8f9fa;
}

.HamburgerButton {
  cursor: pointer;
  margin-top: 9px;
  margin-right: 9px;

  -webkit-tap-highlight-color: transparent;
}

.HamburgerMenuContainer {
  overflow: hidden;

  max-height: 0;
  transition: max-height 2s;
  transition-delay: 0;
  transition-timing-function: ease-in-out;

  display: flex;
  flex-direction: column;
  text-align: right;
}

.HamburgerMenuContainer {
  padding: 5px;
  margin-top: 5px;
}

.HamburgerMenuContainerOpen {
  max-height: 300px !important;
} 
