body {
  margin: 0;
  padding: 0;
	font-family: -apple-system,BlinkMacSystemFont, "Segoe UI", Roboto, 
		"Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", 
		"Segoe UI Emoji","Segoe UI Symbol";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  background-color: #3a3a3a;
  color: #f8f9fa;
}

a, a:focus, a:hover {
  color: #f8f9fa;
	text-decoration: none;
}

.Marginless {
  margin: 0 auto;
}

.Backgrounded {
  background-image: url(/static/media/background3.ac0829f1.jpg);
  background-size: cover;
}

.CoverContainer {
	display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  min-height: 100vh;
  overflow: hidden;

	max-width: 100%;
}

.CoverHeader {
	align-self: flex-start;
  margin: 0 auto;
  margin-bottom: auto;
}

.CoverContent {
  padding: 0 1.5rem;

	display: flex;
	flex-direction: column;
}

.CoverFooter {
  margin-top: auto;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.Content {
  background-color: #3a3a3a;
}

.ContentSection {
  padding: 0.5rem 1.2rem;
}

.Navbar {
	display: flex;
	flex-wrap: wrap;
	list-style: none;
}

.NavElement {
	padding: .25rem 0;
}

.Navbar .NavElement + .NavElement {
	margin-left: 1.5rem;
}

.NavElementHuge {
  padding: 1rem 0;
}

.Navbar .NavElementHuge + .NavElementHuge {
  margin-left: 5rem;
}

.ImgHuge {
  max-width: 25vw;
}

.ImgImportant {
  border-bottom: 4px solid #ffff00;
}

.CenterBlock {
  display: block;
  margin: 0 auto;
}

.ImgFluid {
  max-width: 100%;
}

.ImgFluidMinimized {
  max-width: 60%;
}

.ImgFluidMicro {
  max-width: 40%;
}

.ImgTiny {
  max-width: 200px;
}

.EmbedFluid {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
  margin-bottom: 16px;
}

.EmbedFluid::before {
  padding-top: 100%;
  display: block;
  content: "";
}

.EmbedFluidShort {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
  margin-bottom: 16px;
}

.EmbedFluidShort::before {
  padding-top: 30%;
  display: block;
  content: "";
}

.EmbedFluidItem {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.TextFocus {
  text-align: center;
  font-size: 1.25rem;
  font-weight: 300;

  margin-bottom: 16px;
  
  text-shadow: 0 0.05rem .1rem rgba(0, 0, 0, .5);
}

.TextLeft {
  text-align: left !important;
}

.TextNormal {
  text-align: justify;
  font-size: 1.1rem;
  font-weight: 300;
}

.TextGrand {
  font-weight: 700;
  font-size: 1.25rem;
  text-shadow: 0 0.05rem .1rem rgba(0, 0, 0, .5);
  margin-top: 0.1rem;
  margin-bottom: 0.2rem;
}

.TextHuge {
  font-weight: 700;
  font-size: 1.8rem;
  margin-top: 0.2rem;
  margin-bottom: 0.4rem;
}

.TextTitle {
  margin-top: 16px;
  text-align: center;
}

.TextCenter {
  text-align: center;
}

.TextRight {
  text-align: right;
}

.TextImportant {
  text-decoration: underline;
  -webkit-text-decoration-color: #ffff00;
          text-decoration-color: #ffff00;
}

.Btn {
	align-self: center;

  padding-top: .2rem;
  padding-bottom: .2rem;
  padding-left: .5rem;
  padding-right: .5rem;

  color: #ffff00;
  text-align: center;
  text-shadow: 0 0.05rem .1rem rgba(0, 0, 0, .5);

  border: 1px solid transparent;
  border-width: 3px;
  border-color: #ffff00;
  border-radius: 0;
}

.SocialIcon {
  font-size: 1.8rem;
  padding: 5px;
}

.SocialIconHuge {
  font-size: 2.5rem;
  padding: 0.5rem;
}

a {
  text-decoration: underline;
  -webkit-text-decoration-color: #ffff00;
          text-decoration-color: #ffff00;
  color: #f8f9fa;
  text-align: center;
}

.ContentPadded {
  padding: 2rem 6rem;
}

.FlexLRSplit {
  display: flex;
  align-items: center;
}

.FlexLRSplit * {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.CenteredChildren {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.FlexColumn {
  flex-direction: column;
}

.BlockCenter {
  margin: 0 auto !important;
}
.BackgroundContainer {
  position: absolute;
  left: 0;
  top: 0;
  z-index: -99;

  width: 100vw;
  height: 100vh;
}

@-webkit-keyframes fadeInOut {
  0% {
    opacity:1;
  }
  25% {
    opacity:0;
  }
  75% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@keyframes fadeInOut {
  0% {
    opacity:1;
  }
  25% {
    opacity:0;
  }
  75% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

.BackgroundContainer img { 
  width: 100vw;
  height: 100vh;
  position: absolute;
  overflow: hidden;
  object-fit: cover;

  -webkit-animation-name: fadeInOut;

          animation-name: fadeInOut;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-duration: 6s;
          animation-duration: 6s;
}

.BackgroundContainer img:nth-of-type(1) {
  -webkit-animation-delay: 5s;
          animation-delay: 5s;
}
.BackgroundContainer img:nth-of-type(2) {
  -webkit-animation-delay: 3s;
          animation-delay: 3s;
}
.BackgroundContainer img:nth-of-type(3) {
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
}

.NavbarContainer {
  background-color: #505050;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1023;

  min-height: 3rem;

  padding: .5rem 1rem;

  box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175);

  display: flex;

  align-items: center;
}

.NavbarContainer div {
  margin-left: auto;
}

.Logo {
  height: 30px;
  margin-top: 4px;
}

.HamburgerMenuContainer a {
  margin-top: 0.2rem;
  text-decoration: underline;
  -webkit-text-decoration-color: #ffff00;
          text-decoration-color: #ffff00;
}

.PostNavbarSpacing {
  height: 4rem;
}

.HamburgerButtonBar {
  width: 25px;
  height: 3px;
  margin: 4px 0;
  background-color: #f8f9fa;
}

.HamburgerButton {
  cursor: pointer;
  margin-top: 9px;
  margin-right: 9px;

  -webkit-tap-highlight-color: transparent;
}

.HamburgerMenuContainer {
  overflow: hidden;

  max-height: 0;
  -webkit-transition: max-height 2s;
  transition: max-height 2s;
  -webkit-transition-delay: 0;
          transition-delay: 0;
  -webkit-transition-timing-function: ease-in-out;
          transition-timing-function: ease-in-out;

  display: flex;
  flex-direction: column;
  text-align: right;
}

.HamburgerMenuContainer {
  padding: 5px;
  margin-top: 5px;
}

.HamburgerMenuContainerOpen {
  max-height: 300px !important;
} 

.FooterContainer {
  background-color: #505050;
  width: 100vw;
  padding-top: 0.5rem;
  padding-bottom: 0.25rem;

  line-height: 1.5;
  font-size: 1.0rem;
  color: #6c757d;

  text-align: center;
}

.FooterContainer a {
  line-height: 1;
  text-decoration: underline;
  -webkit-text-decoration-color: #ffff00;
          text-decoration-color: #ffff00;
  color: #f8f9fa;
  font-size: 1.25rem;
}

.ScrollPage {
  background-color: #3a3a3a;
}

.ScrollTabNav {
  border-bottom: 2px solid #ffff00;
  padding: 0.2rem;

  display: flex;
  align-items: center;
}

.ScrollTabNav * {
  margin: 0 auto;
}

.SplitView {
  width: 100%;
  height: 100vh;

  display: flex;
}

.SplitViewMaster {
  width: 30%;
  height: 100%;
  background-color: #303030;

  background-image: url(/static/media/background3.ac0829f1.jpg);
  background-size: cover;
}

.SplitViewMasterBody {
  margin-top: 1rem;
  align-self: flex-end;
  margin-right: 4rem;
}

.SplitViewDetails {
  height: 100vh;
  overflow: auto;
  overflow-x: hidden;
  width: 70%;
  box-shadow: 0px 0 50px 0 rgba(0, 0, 0, 0.5);
}
.SlideFadeFromRight-enter {
  opacity: 0.01;
  -webkit-transform: translate(5%);
          transform: translate(5%);
}

.SlideFadeFromRight-enter.SlideFadeFromRight-enter-active {
  opacity: 1;
  -webkit-transform: translate(0);
          transform: translate(0);
  -webkit-transition: all 500ms ease-out;
  transition: all 500ms ease-out;
}

.SlideFadeFromRight-leave {
  -webkit-transform: translate(0);
          transform: translate(0);
  opacity: 1;
}

.SlideFadeFromRight-leave.SlideFadeFromRight-leave-active {
  opacity: 0.01;
  -webkit-transform: translate(5%);
          transform: translate(5%);
  -webkit-transition: all 500ms ease-out;
  transition: all 500ms ease-out;
}
.ProjectsSplitViewMaster {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ProjectsHeadImage {
  position: absolute;
  top: 10%;
}
.ShowcaseImgFluid {
  max-width: 100%;
  max-height: 80vh;
  margin: 10px auto;
}

.ShowcaseContentSection {
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
