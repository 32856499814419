.ProjectsSplitViewMaster {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ProjectsHeadImage {
  position: absolute;
  top: 10%;
}