body {
  margin: 0;
  padding: 0;
	font-family: -apple-system,BlinkMacSystemFont, "Segoe UI", Roboto, 
		"Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", 
		"Segoe UI Emoji","Segoe UI Symbol";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  background-color: #3a3a3a;
  color: #f8f9fa;
}

a, a:focus, a:hover {
  color: #f8f9fa;
	text-decoration: none;
}
