.ScrollPage {
  background-color: #3a3a3a;
}

.ScrollTabNav {
  border-bottom: 2px solid #ffff00;
  padding: 0.2rem;

  display: flex;
  align-items: center;
}

.ScrollTabNav * {
  margin: 0 auto;
}
